<template>
  <custom-misc v-if="view">
    <span class="icon-page-misc mb-2">
      <LockIcon />
    </span>

    <div class="text-center mb-1">
      <h2 class="text-center">
        Acesso negado
      </h2>
      <p class="text-center">
        Você não tem permissão para acessar este recurso.
        Para liberar ou verificar seu acesso entre em contato com o suporte.
      </p>
    </div>

    <button
      type="button"
      class="btn btn-misc w-100"
      @click="handleRedirect"
    >
      Voltar para o início
    </button>
  </custom-misc>
</template>

<script>
import { LockIcon } from 'vue-feather-icons'
import CustomMisc from '@/views/components/custom/miscellaneous/CustomMisc.vue'
import { clearStore, isUserLoggedIn, logoutUserRedirectToLogin } from '@/auth/utils'

export default {
  title: 'Página não autorizada',

  components: {
    CustomMisc,
    LockIcon,
  },

  data() {
    return {
      view: false,

      countClicks: 0,
    }
  },

  created() {
    if (isUserLoggedIn) {
      this.view = true
    } else {
      clearStore()
      this.$router.replace({ name: 'auth-login' })
    }
  },

  methods: {
    handleRedirect() {
      this.countClick()

      this.$router.replace({ name: 'home' })
    },

    countClick() {
      this.countClicks++

      if (this.countClicks > 1) {
        logoutUserRedirectToLogin()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
